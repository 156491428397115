export const environment = {
  production: false,
  region: 'US_EAST_1',
  identityPoolId: '',
  userPoolId: 'us-east-1_jFX2OAKOF',
  clientId: '4le2eqr48j0720s8m8rlq1ha6i',
  cognito_idp_endpoint: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_jFX2OAKOF',
  cognito_identity_endpoint: '',
  sts_endpoint: '',
  baseUrl_IncomingQueue: "https://api.getcamber.com/iq-prod1",
  baseUrl_Bus_CCSS_Display: "https://api.getcamber.com/dataLake-prod1",
  baseUrl_RecommendedRun: "https://api.getcamber.com/runs-prod1",
  baseUrl_Garage_Tracks: "https://api.getcamber.com/ccss-prod1",
  baseUrl_CustomerAssets: "https://api.getcamber.com/ams-prod1",
  GarageId: "239d8e65-a80b-4064-90f4-3501d182d65b",
  TenantId: "2cbbfd0a-0ff7-4062-89da-bf2fa63807b7",
  HistoryId: "00120C2DF6B09F01",
  //  APEX_URL: 'http://prod.apex.proterra.com.s3-website-us-east-1.amazonaws.com'
  APEX_URL: 'https://staging-connected.proterra.com',
  AGREEMENT_URL : 'https://api.getcamber.com/docusign-prod1/v1'
};
